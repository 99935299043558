<template>
  <div class="page">
    <div class="heading">
      <v-toolbar :flat="flatApp" dense>
        <v-btn icon @click="menuLink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs
          :items="crumbs"
          class="capitalize"
          style="padding: 14px 20px"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="primary" text @click="refresh" v-on="on">
              <v-icon dark size="25">mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-card outlined>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="dataList"
            :items-per-page="10"
            :loading="loading"
            :sort-by="['id']"
            :sort-desc="[true]"
            loader-height="1"
          >
            <template v-slot:item.pay_type="{ item }">
              {{ capitalize(payType(item.pay_type)) }}
            </template>
            <template v-slot:item.amount="{ item }">
              {{ currency(item.amount) }}
            </template>
            <template v-slot:item.pay_status="{ item }">
              {{ payStatus(item.pay_status) }}
            </template>
            <template v-slot:item.status="{ item }">
              <v-icon>
                {{ item.status ? "mdi-check" : "mdi-close" }}
              </v-icon>
            </template>

            <!-- -->
            <template v-slot:item.id="{ item }">
              <v-btn color="primary" text x-small @click.stop="verify(item.id)">
                verify payment
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
    <v-snackbar
      v-model="snack.bar"
      :color="snack.color"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Util from "../../mixins/utils";
import WinScroll from "../../mixins/windowscroll";

export default {
  name: "paymentMade",
  mixins: [Util, WinScroll("position")],
  data: () => ({
    crumbs: [
      {
        exact: true,
        link: true,
        text: "Dashboard",
        to: { name: "patient.dash" },
      },
      {
        exact: true,
        link: true,
        text: "Appointment",
        to: { name: "patient.appointments" },
      },
    ],
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      text: "Processing ... ",
    },
    loading: false,
    dataList: [],
    headers: [
      { text: "payment type", value: "pay_type" },
      { text: "amount", value: "amount" },
      { text: "pay status", value: "pay_status", align: "center" },
      { text: "phone", value: "phone" },
      { text: "status", value: "status" },
      { text: "actions", value: "id", align: "center" },
    ],
  }),
  computed: {
    flatApp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.list();
  },
  methods: {
    menuLink() {
      this.$emit("sidebar");
    },
    list() {
      this.loading = true;
      let self = this;
      Restful.checkout
        .transactions()
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.dataList = response.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.loading = false;
        });
    },
    payType(a) {
      if (a === "mobile_money") {
        return "mobile money";
      } else if (a === "cc") {
        return "credit card";
      } else if (a === "cash") {
        return "cash";
      }
    },
    payStatus(a) {
      if (a === "success" || a === "paid") {
        return "paid";
      } else if (a === "fail" || a === "failed") {
        return "failed";
      }
    },
    refresh() {
      this.list();
    },
    verify(a) {
      this.snack.bar = true;
      this.snack.text = "Processing ...";
      this.snack.color = "primary";
      let id = a;
      let da = { id };
      let self = this;
      Restful.checkout
        .verify(da)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.snack.color = "success";
            this.snack.text = response.data;
            this.list();
          }
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.loading = false;
        });
    },
  },
};
</script>
